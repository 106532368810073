import { usPhoneNumberPattern, emailPattern } from '../actions/helpers'

export const wrapTextAddLinks = (text, preventFocus) => {
  text = findPhoneLink(text, preventFocus)
  text = findUrlLink(text, preventFocus)
  text = findEmails(text)
  text = convertBoldText(text)
  text = convertItalicText(text)
  text = replaceSpanWithA(text)

  return text
}

const findEmails = text => {
  if (!emailPattern.test(text)) return text

  return text.replace(emailPattern, email => {
    const sanitizedEmail = email.replace(/^\*\*(.*?)\*\*$/g, '$1')
    return `<a class="link" href="mailto:${sanitizedEmail}">${sanitizedEmail}</a>`
  })
}

const findPhoneLink = (text, preventFocus) => {
  if (!usPhoneNumberPattern.test(text)) return text

  return text.replace(
    usPhoneNumberPattern,
    url => `<a class="link" href="tel:${url}" tabIndex=${preventFocus ? -1 : 0}>${url}</a>`,
  )
}

const urlWithTextPattern = /\[(.*?)\]\((https?:\/\/[^\s]+)\)/gi // Matches [link text](link)
const urlWithoutTextPattern =
  // eslint-disable-next-line max-len
  /(?<!<a [^>]*href=")(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*))(?![^<]*<\/a>)/gi

const findUrlLink = (text, preventFocus) => {
  text = text.replace(urlWithTextPattern, (_, linkText, url, index) => {
    return `<a href="${withHttp(url)}" class="linkCreated" target="_newTab${index}" tabIndex=${
      preventFocus ? -1 : 0
    }>${linkText}</a>`
  })

  return text.replace(urlWithoutTextPattern, (url, index) => {
    return `<a href="${withHttp(url)}" class="linkCreated" target="_newTab${index}" tabIndex=${
      preventFocus ? -1 : 0
    }>${url}</a>`
  })
}

const withHttp = link => {
  if (!/[a-zA-Z0-9]/.test(link.slice(-1))) link = link.slice(0, -1)
  const url = !/^https?:\/\//i.test(link) ? `http://${link}` : link
  return ' ' + url.trim() + ' '
}

const convertBoldText = text => {
  return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
}

const convertItalicText = text => text.replace(/\*(.*?)\*/g, '<em>$1</em>')

const replaceSpanWithA = text => {
  return text.replace(/(<span)/gim, '<a').replace(/<\/span>/gim, '</a>')
}
