import React, { Component, createRef } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransition'

import { getWidgetConfig, trackWidgetEvent } from '../api/api'
import Container from '../components/containers/Container/Container'
import Button from '../components/Button'
import MessagesPreview from '../components/MessagesPreview'
import {
  checkIsRightButtonNeeded,
  fullScreenWidth,
  fullScreenHeight,
  transitionSettingsContainer,
  transitionSettingsLauncher,
  detectSiteLanguageOrDefault,
  popupMessageDelay,
} from './config'
import { API_URL, setAPIUrl } from '../config/config'
import { generateWidgetChatId } from '../actions/helpers'
import { ChatRoleMap } from '../constants'

import './App.css'
import { WIDGET_WIDTH, WIDGET_HEIGHT, BUBBLE_WIDTH, BUBBLE_HEIGHT, bubbleButtonPadding } from '../constants/config'
import { fetchChatHistory } from '../fetchers/fetchChatHistory'
import { convert3To6DigitHex } from '../helper/convert3To6DigitHex'
import { getDomainName } from '../helper/getDomainName'
import { getClearURL } from '../helper/getClearUrl'

import * as S from './App.style'

const INITIAL_MESSAGES_COUNT = 500

class App extends Component {
  constructor(props) {
    super(props)
    this.myRef = createRef()
    this.setRequestSentAt = this.props.setRequestSentAt

    this.state = {
      user: null,
      init: false,
      settings: {},
      authorized: false,
      openWidget: true,
      showPopup: false,
      showUnreadIcon: false,
      websiteLocation: '',
      settingsUpdated: false,
      isLoadingChatHistory: false,
      isFullScreenWidget: false,
      wasAutomaticallyOpened: false,
      device: {
        width: 0,
        height: 0,
      },
      textDisabled: true,
      isHiddenWidget: true,
      isCustomBubble: false,
      isSomewhereWidgetOpen: false,
      inputTogglerValue: '',
      widgetPosition: {},
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.messageEventListener.bind(this))
    this.initializeWidget()
  }

  componentDidUpdate(_, prevState) {
    const { websiteLocation, init, settingsUpdated, openWidget } = this.state

    const isShouldFrameResize = openWidget !== prevState.openWidget || settingsUpdated !== prevState.settingsUpdated

    if (isShouldFrameResize) {
      const widgetOffsetWidth = this.myRef.current.offsetWidth + 'px'
      const widgetOffsetHeight = this.myRef.current.offsetHeight + 'px'

      this.setFrameSize(widgetOffsetWidth, widgetOffsetHeight)
    }

    if (openWidget !== prevState.openWidget) {
      this.postMessageToggleWidget(openWidget)
    }

    if (websiteLocation && !init && settingsUpdated) {
      this.initialFunction()
    }
  }

  initializeWidget() {
    // prettier-ignore
    window.parent.postMessage( //NOSONAR
      {
        type: 'widget-set-url',
        chatId: generateWidgetChatId(),
      },
      '*',
    )
  }

  initialFunction() {
    const { settings, isMinimizedWidget, openByDefault } = this.state
    const { chatId, isOpen, inputTogglerValue } = this.props.browserStorage
    const isEnabledStorage = settings.chatHistoryLevel !== 'NONE'
    const authorized = chatId && isEnabledStorage

    if (inputTogglerValue) {
      this.setState({ inputTogglerValue })
    }
    if (isOpen !== null && chatId !== null && !isMinimizedWidget) {
      this.toggleBackground(isOpen)
      this.setState({ openWidget: isOpen, isHiddenWidget: !isOpen })
    }

    if (openByDefault) {
      this.setState(
        {
          openWidget: true,
          init: true,
          isHiddenWidget: false,
          authorized,
        },
        () => {
          this.resizeWidget()
        },
      )
    } else if (this.state.greeting) {
      const popupDelayTimeout = setTimeout(() => {
        const isConversationStarted = this.props.messages?.some(msg => msg.role === ChatRoleMap.user)

        if (!this.state.openWidget && !this.state.showPopup && !isConversationStarted) {
          this.setState({ showPopup: true })

          this.state.websiteLocation &&
            window.parent.postMessage(
              {
                type: 'update-greeting',
                greetings: [{ text: this.state.greeting }],
                width: '380px',
              },
              this.state.websiteLocation,
            )
        }
      }, popupMessageDelay)
      this.setState({
        init: true,
        popupDelayTimeout,
      })
    } else {
      this.setState({
        init: true,
        authorized,
      })
    }
  }

  setUnreadIconTimeoutId = unreadIconTimeoutId => this.setState({ unreadIconTimeoutId })

  getCookieValue(name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  }

  tryPostVisitorData({ backendUrl, botId, originUrl, userAgent, visitorId, browserLanguage }) {
    const url = getClearURL(originUrl)
    const cookie = this.getCookieValue(`pagesVisited-${botId}`)
    const pagesVisited = JSON.parse(decodeURIComponent(cookie) || '{"pages": [], "channel": "widget"}')

    if (pagesVisited.pages.includes(url) || pagesVisited.channel !== 'widget') {
      return
    }

    pagesVisited.pages.push(url)

    document.cookie = `pagesVisited-${botId}=${encodeURIComponent(
      JSON.stringify(pagesVisited),
    )};domain=.${getDomainName(new URL(backendUrl).hostname)}; max-age=34560000; SameSite=None; Secure`

    const apiUrl = `${backendUrl}/api/public/bot/${botId}/visitor`
    const body = JSON.stringify({
      originUrl: url,
      userAgent,
      visitorId,
      browserLanguage,
    })

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-widget': 'true',
      },
      body,
      cache: 'no-store',
    })
  }

  messageEventListener(event) {
    if (event.data.type === 'initialization') {
      this.configureWidget(event).then(result => {
        if (result) {
          this.props.setCurrentUrl(event.data.originUrl)
          this.tryPostVisitorData({
            backendUrl: event.data.serverUrl,
            botId: event.data.botId,
            originUrl: event.data.originUrl,
            userAgent: event.data.userAgent,
            visitorId: parseInt(event.data.visitorId),
            browserLanguage: event.data.browserLanguage,
          })
        }
      })
    }

    if (event.data.type === 'reset-chat-history') {
      this.resetChat()
    }

    if (event.data.type === 'clear-chat-history') {
      this.clearChat()
    }

    if (event.data.type === 'is-widget-open-at-some-tab') {
      this.setState({
        isSomewhereWidgetOpen: event.data.isWidgetOpen,
      })
    }

    if (event.data.type === 'widget-resize-screen') {
      const skipWidgetResizing = !this.state.openWidget && this.state.showPopup && !this.state.isMobile

      this.setState(
        {
          screenSize: event.data.screenSize,
        },
        () => {
          if (skipWidgetResizing) return
          this.resizeWidget(this.state.isFullScreenWidget)
        },
      )
    }
  }

  getInitialMessages = (backendUrl, botId, chatId, welcomeMessage, appearanceDelay) => {
    this.setState({
      isLoadingChatHistory: true,
    })
    fetchChatHistory([backendUrl, botId, chatId, INITIAL_MESSAGES_COUNT])
      .then(msgs => {
        this.handleDelayWidgetOpening(appearanceDelay, !!msgs.length)
        if (msgs.length > 0) {
          this.props.setInitialMessages(msgs)
          const lastMessage = msgs[msgs.length - 1]
          if (lastMessage.role === ChatRoleMap.assistant) {
            this.props.setQuickReplies({
              quickReplies: lastMessage.quickReplies || [],
              messageId: lastMessage.id || '',
            })
          }
        } else {
          this.props.setInitialMessages([{ role: 'assistant', content: welcomeMessage }])
        }
      })
      .catch(() => this.props.setInitialMessages([{ role: 'assistant', content: welcomeMessage }]))
      .finally(() =>
        this.setState({
          isLoadingChatHistory: false,
        }),
      )
  }

  configureWidget = event => {
    const language = detectSiteLanguageOrDefault(event.data.url)
    const chatId = event.data.storage?.widgetChatId || event.data.chatId

    this.props.initializeStorage(event.data.url, {
      ...event.data.storage,
      serverUrl: event.data.serverUrl,
      botId: event.data.botId,
      chatId,
    })
    setAPIUrl(event.data.serverUrl)

    return getWidgetConfig(event.data.botId, event.data.originUrl).then(widgetConfig => {
      if (!widgetConfig) {
        console.warn('NoForm.AI: Chat bubble config is missing. This might mean that subscription is not active.')
        return false
      }
      this.props.updateBrowserStorage(
        { botId: event.data.botId, chatId, quickRepliesEnabled: widgetConfig?.quickRepliesEnabled },
        event.data.url,
      )
      this.getInitialMessages(
        event.data.serverUrl,
        event.data.botId,
        chatId,
        widgetConfig.welcomeMessage,
        widgetConfig.appearanceDelay,
      )

      this.updateWidgetPosition(event.data.url, widgetConfig.bubblePosition, Boolean(event.data.storage?.isOpen))
      if (event.data.isTestMode) {
        this.setBrowserTabName(widgetConfig?.header, event.data.url)
      }

      this.setState({
        botId: event.data.botId,
        settingsUpdated: true,
        attributes: event.data.attributes,
        isCustomBubble: event.data.isCustomBubble,
        isMobile: event.data.isMobile,
        user: this.state.user || { language },
        websiteLocation: event.data.url,
        device: event.data.device,
        isMinimizedWidget: event.data.isMinimizedWidget,
        settings: { ...widgetConfig, color: convert3To6DigitHex(widgetConfig.color) },
        greeting: widgetConfig.welcomeMessage,
        conversationStarters: widgetConfig.conversationStarters || [],
        conversationStartersEnabled: widgetConfig.conversationStartersEnabled || false,
        quickRepliesEnabled: widgetConfig.quickRepliesEnabled || false,
        openByDefault: event.data.open,
        isSomewhereWidgetOpen: event.data.isWidgetOpen,
        screenSize: event.data.screenSize,
        widgetPosition: widgetConfig.bubblePosition,
        currentUrl: event.data.currentUrl,
      })
      return true
    })
  }

  handleDelayWidgetOpening = (delay, conversationStarted) => {
    if (delay && !conversationStarted) {
      setTimeout(() => {
        if (!this.state.openWidget) {
          this.props.updateBrowserStorage({ isOpen: true })
          this.setState({ openWidget: true, showUnreadIcon: false, wasAutomaticallyOpened: true })
        }
      }, delay)
    }
  }

  resetChat = () => {
    this.setState(
      {
        chatId: null,
        openWidget: false,
        authorized: false,
        isFullScreenWidget: false,
        user: null,
        messages: [],
      },
      () => {
        this.resizeWidget(false)

        this.state.websiteLocation && window.parent.postMessage({ type: 'reload-page' }, this.state.websiteLocation)
      },
    )
  }

  updateWidgetPosition = (websiteLocation, widgetPosition, isWidgetOpen) => {
    // Here we subtract from the config margins bubbleButtonPadding to
    // provide animation and scaling of our bubble button
    const optimizedWidgetPosition = {
      ...widgetPosition,
      horizontalMargin: widgetPosition.horizontalMargin - bubbleButtonPadding,
      verticalMargin: widgetPosition.verticalMargin - bubbleButtonPadding,
    }
    websiteLocation &&
      window.parent.postMessage(
        { type: 'update-widget-position', widgetPosition: optimizedWidgetPosition, isWidgetOpen },
        websiteLocation,
      )
  }

  clearChat = () => {
    this.setState(
      {
        chatId: null,
        openWidget: false,
        authorized: false,
        isFullScreenWidget: false,
        user: null,
        messages: [],
      },
      () => {
        this.resizeWidget(false)
        this.state.websiteLocation && window.parent.postMessage({ type: 'clear-storage' }, this.state.websiteLocation)
      },
    )
  }

  setFrameSize(newWidth, newHeight) {
    const { isMobile, websiteLocation } = this.state
    window.parent.postMessage(
      {
        type: 'widget',
        width: newWidth,
        height: newHeight,
        needDelay: isMobile,
      },
      websiteLocation,
    )
  }

  postMessageToggleWidget(isWidgetOpen) {
    this.state.websiteLocation &&
      window.parent.postMessage(
        {
          type: 'toggle-widget',
          isWidgetOpen,
        },
        this.state.websiteLocation,
      )
  }

  setInputField = textDisabled => {
    if (this.state.textDisabled !== textDisabled) {
      this.setState({ textDisabled })
    }
  }

  setTextTogglerValue = value => {
    this.props.updateBrowserStorage({ inputTogglerValue: value })
    this.setState({ inputTogglerValue: value })
  }

  resizeWidget = isFullScreenWidget => {
    const widgetOffsetWidth = this.state.openWidget ? WIDGET_WIDTH : BUBBLE_WIDTH
    const widgetOffsetHeight = this.state.openWidget ? WIDGET_HEIGHT : BUBBLE_HEIGHT
    const width = isFullScreenWidget ? fullScreenWidth : widgetOffsetWidth
    const height = isFullScreenWidget ? fullScreenHeight : widgetOffsetHeight
    this.setState(
      {
        isFullScreenWidget,
      },
      () => {
        this.state.websiteLocation &&
          window.parent.postMessage(
            {
              type: 'resize-widget',
              width,
              height,
              isFullScreenWidget: this.state.isFullScreenWidget,
              isMobile: this.state.isMobile,
            },
            this.state.websiteLocation,
          )
      },
    )
  }

  toggleWidget = () => {
    const { popupDelayTimeout, openWidget, unreadIconTimeoutId } = this.state
    clearTimeout(popupDelayTimeout)
    clearTimeout(unreadIconTimeoutId)

    this.props.updateBrowserStorage({ isOpen: !openWidget })
    this.setState({ openWidget: !openWidget, showUnreadIcon: false })
  }

  trackWidgetEvent = event => {
    const { botId, chatId } = this.props.browserStorage
    trackWidgetEvent(API_URL, botId, chatId, event)
  }

  openWidget = () => {
    this.props.updateBrowserStorage({ isOpen: true })
    this.setState({ openWidget: true, showPopup: false })
  }

  toggleBackground = isBackgroundEnable => {
    const { settings } = this.state
    const isEnabledStorage = settings.chatHistoryLevel !== 'NONE'
    if (!settings.isBackgroundBlurred) return
    if (!isBackgroundEnable && isEnabledStorage) {
      this.setState({ messages: [] })
    }

    this.state.websiteLocation &&
      window.parent.postMessage(
        {
          type: 'widget-background',
          background: isBackgroundEnable,
        },
        this.state.websiteLocation,
      )
  }

  setShowPopup = shouldShow => this.setState({ showPopup: shouldShow })

  setShowUnreadIcon = showUnreadIcon => this.setState({ showUnreadIcon })

  closeWidget = () => {
    this.setState(
      {
        openWidget: false,
      },
      () => {
        this.toggleBackground(this.state.openWidget)
        this.props.updateBrowserStorage({ isOpen: this.state.openWidget })
        if (this.state.isFullScreenWidget) {
          this.resizeWidget(false)
        }
      },
    )
  }

  updateUserData = user => {
    user.language = detectSiteLanguageOrDefault(this.state.websiteLocation)
    this.props.updateBrowserStorage({ isOpen: true })
    this.setState(
      {
        user,
        openWidget: true,
        authorized: true,
      },
      () => {
        this.toggleBackground(true)
      },
    )
  }

  handleHiddenWidget = () => {
    this.setState({ isHiddenWidget: true }, this.toggleWidget)
  }

  handleViewWidget = () => {
    this.setState({ isHiddenWidget: false }, this.toggleWidget)
  }

  replaceMessages = messages => {
    this.setState({ messages })
  }

  setBrowserTabName = (botName, websiteLocation) => {
    window.parent.postMessage(
      {
        type: 'set-browser-tab-name',
        botName,
      },
      websiteLocation,
    )
  }

  render() {
    const {
      botId,
      device,
      isMobile,
      settings,
      openWidget,
      textDisabled,
      websiteLocation,
      settingsUpdated,
      isFullScreenWidget,
      isHiddenWidget,
      isCustomBubble,
      greeting,
      screenSize,
      inputTogglerValue,
      showPopup,
      conversationStarters,
      conversationStartersEnabled,
      isLoadingChatHistory,
      quickRepliesEnabled,
      widgetPosition,
      wasAutomaticallyOpened,
    } = this.state
    const { messages, quickReplies, isLoading, input, handleInputChange, handleSubmit, setQuickReplies, append } =
      this.props
    const isButtonNeeded = checkIsRightButtonNeeded(device, openWidget, isFullScreenWidget)
    const conversationStarted = messages.some(msg => msg.role === ChatRoleMap.user)
    const hideConversationStarters = isLoadingChatHistory || conversationStarted || !conversationStartersEnabled
    const { corner = '' } = widgetPosition
    const [, startMarginFromXAxis = 'right'] = corner.split('_')
    const alignItems = startMarginFromXAxis.toLowerCase() === 'right' ? 'flex-end' : 'flex-start'
    const assistantName = settings?.header

    return (
      <>
        {settingsUpdated && (
          <S.App ref={this.myRef} alignItems={alignItems} isFullScreenWidget={isFullScreenWidget}>
            <BrowserRouter basename="/">
              <>
                {openWidget && (
                  <ReactCSSTransitionGroup {...transitionSettingsContainer}>
                    <Container
                      openWidget={openWidget}
                      device={device}
                      isMobile={isMobile}
                      messages={messages}
                      quickReplies={quickReplies}
                      setQuickReplies={setQuickReplies}
                      quickRepliesEnabled={quickRepliesEnabled}
                      trackWidgetEvent={this.trackWidgetEvent}
                      isHiddenWidget={isHiddenWidget}
                      settings={settings}
                      parentURL={websiteLocation}
                      textDisabled={textDisabled}
                      inputTogglerValue={inputTogglerValue}
                      toggleWidget={this.toggleWidget}
                      handleHiddenWidget={this.handleHiddenWidget}
                      isFullScreenWidget={isFullScreenWidget}
                      screenSize={screenSize}
                      handleSoundNotification={this.handleSoundNotification}
                      input={input}
                      handleInputChange={handleInputChange}
                      handleSubmit={handleSubmit}
                      isLoading={isLoading}
                      conversationStarters={conversationStarters}
                      hideConversationStarters={hideConversationStarters}
                      append={append}
                      setRequestSentAt={this.setRequestSentAt}
                    />
                  </ReactCSSTransitionGroup>
                )}
                <ReactCSSTransitionGroup {...transitionSettingsLauncher}>
                  <S.ButtonWrap alignItems={alignItems}>
                    <MessagesPreview
                      device={device}
                      isMobile={isMobile}
                      greeting={greeting}
                      alignItems={alignItems}
                      isPopupsNeeded={!openWidget && showPopup}
                      widgetXPosition={startMarginFromXAxis.toLowerCase()}
                      openWidget={this.openWidget}
                      setShowPopup={this.setShowPopup}
                      setShowUnreadIcon={this.setShowUnreadIcon}
                      setUnreadIconTimeoutId={this.setUnreadIconTimeoutId}
                      resizeWidget={this.resizeWidget}
                      conversationStarters={conversationStarters}
                      settings={settings}
                      append={append}
                      hideConversationStarters={hideConversationStarters}
                      conversationStarted={conversationStarted}
                      wasAutomaticallyOpened={wasAutomaticallyOpened}
                      trackWidgetEvent={this.trackWidgetEvent}
                      setRequestSentAt={this.setRequestSentAt}
                      assistantName={assistantName}
                      websiteLocation={this.state.websiteLocation}
                    />
                    <Button
                      botId={botId}
                      settings={settings}
                      messages={messages}
                      isWidgetOpened={openWidget}
                      isButtonNeeded={!isCustomBubble && isButtonNeeded}
                      showUnreadIcon={this.state.showUnreadIcon}
                      toggleWidget={this.toggleWidget}
                      setShowPopup={this.setShowPopup}
                      trackWidgetEvent={this.trackWidgetEvent}
                      greeting={greeting}
                    />
                  </S.ButtonWrap>
                </ReactCSSTransitionGroup>
              </>
            </BrowserRouter>
          </S.App>
        )}
      </>
    )
  }
}

export default App
