import sanitizeHtml from 'sanitize-html'

export const getSanitizedHtml = (dirtyHtml: string): string => {
  const options: sanitizeHtml.IOptions = {
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { target: '_blank' }),
    },
  }

  return sanitizeHtml(dirtyHtml, options)
}
